import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import authReducer from "./authReducer";
import thunkMiddleWare from "redux-thunk";
import blogReducer from "./blogReducer";
import productsReducer from "./ProductsReducer";

let reducers=combineReducers({
    authPage:authReducer,
    // clientPage:clientReducer,
    // buildingPage:buildingReducer,
    // deviceGroupPage:deviceGroupReducer,
    blogPage:blogReducer,
    productsPage:productsReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleWare)));

// let store=createStore(reducers, applyMiddleware(thunkMiddleWare));
window.__store=store;
export default store;














