import {store} from "react-notifications-component";

const ShowNotification = (title,message,type,duration) => {
        store.addNotification({
            title: `${title}`,//"Succes!",
            message: `${message}`,//"Your Logged With Success",
            type: `${type}`, //"info",
            insert: "center",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: duration?duration:1300,
                onScreen: true
            }
        });
    }

export default ShowNotification;
