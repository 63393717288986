import React, {useEffect, useState,useRef} from 'react';
// import image1 from "../assets/products/2.jpg"
import HTMLReactParser from 'html-react-parser';
import ReCaptchaV2 from 'react-google-recaptcha'
import style from "./Product.module.css"
import "./style.css"
import {Form,Formik} from "formik";
import * as Yup from 'yup';
import {TextField} from "../../components/common/TextField/TextField";
import {fileUrl, kapitalAPI, salesAPI} from "../../api/api";
import ShowNotification from "../../components/common/Notification/ShowNotification";
import dateFormat  from "dateformat";
import {clearAll, readFromStorage, saveToStorage} from "../../api/storageApi";
import Modall from "../../components/Modals/VerticalModal";

const SiteKey="6Levf3YcAAAAAL-DQ6rSiHi-lNvYjHEIxJLM1gP9" //test
// const SiteKey="6Levf3YcAAAAAFb0orwtm3piRg5YVcN4kks8EVww" //prod

export const Product = (props) => {
    let pageStatus={notSended:"notSended", Sended:"Sended",Returned:"Returned"}
    let paymentStatus={waiting:0, purchased:1}
    let pstatus= readFromStorage('pStatus')
    const [currentPageStatus,setCurrentStatus]=useState(pstatus?pstatus:pageStatus.notSended)
    const [currentPaymentStatus,setPaymenttatus]=useState(paymentStatus.waiting)
    let productSelectedd=props.products.filter(el=>el.id===parseInt(props.productId))[0]
    let productId=productSelectedd.id
    let productType=productSelectedd.productType
    let productPrice=productSelectedd.price
    // productPrice="001";
    let orderSaved=readFromStorage("orderId")
    let sessionSaved=readFromStorage("sessionId")
    let createdDbIdSaved=readFromStorage("createdOrderId")
    const [orderId, setOrderId] =useState(orderSaved?orderSaved:"")
    const [createdDbOrderId, setCreatedDbOrderId] =useState(parseInt(createdDbIdSaved)?parseInt(createdDbIdSaved):0)
    // let createdDbOrderIdRef= useRef(0)
    const [sessionId, setSessionId] =useState(sessionSaved?sessionSaved:"")
    const [visibleModal, setVisibleModal]= useState(false)

    let urlsite=window.location.href

    const PurchaseForm=()=>{
        return(
            `<?xml version="1.0" encoding="UTF-8"?>
            <TKKPG>
                <Request>
                <Operation>CreateOrder</Operation>
                <Language>RU</Language>
                <Order>
                    <OrderType>Purchase</OrderType>
                    <Merchant>E1130005</Merchant>
                    <Amount>`+productPrice.replace(".","")+`</Amount>
                    <Currency>944</Currency>
                    <Description>`+productSelectedd.title+`</Description>
                    <ApproveURL>`+urlsite+`</ApproveURL>
                    <CancelURL>`+urlsite+`</CancelURL>
                    <DeclineURL>`+urlsite+`</DeclineURL>
                </Order>
            </Request>
        </TKKPG>`
        )
    }
    const setUrlForPayment=(orderId,sessionId)=>{
        return `https://e-commerce.kapitalbank.az/index.jsp?ORDERID=${orderId}&SESSIONID=${sessionId}`
    }
    const OrderInformationXml=(orderId,sessionId)=>{
        return(
            `<?xml version="1.0" encoding="UTF-8"?>
            <TKKPG>
                <Request>
                    <Operation>GetOrderInformation</Operation>
                    <Language>EN</Language>
                    <Order>
                        <Merchant>E1130005</Merchant>
                        <OrderID>${orderId}</OrderID>
                    </Order>
                    <SessionID>${sessionId}</SessionID>
                </Request>
            </TKKPG>`
        )
    }
    const OpenPaymantWindow=(url)=>{
        setCurrentStatus(pageStatus.Sended)
        saveToStorage('pStatus',currentPageStatus)
        debugger
        window.location.assign(url)
    }
    const [isPaymentSuccess, setPaymentSuccess] = useState(false);

    useEffect(async ()=>{
        if(currentPageStatus == 'Sended'){
            if (orderId !== "" && sessionId !== "")
            {
                let response = await kapitalAPI.addOrCheckPaymant(OrderInformationXml(orderId,sessionId))
                if((response !== undefined || response !== null) && response.row !== null){
                        if(response.row.Orderstatus !== null || response.row.Orderstatus !== undefined){
                            if(response.row.Orderstatus == "APPROVED"){
                                debugger
                                ShowNotification("Uğurlu tranzaksiya", "Ödəniş uğurla həyata keçirildi", "success", 10)
                                setPaymentSuccess(true)
                                setVisibleModal(true)
                                salesAPI.editSalesStatus(createdDbOrderId, paymentStatus.purchased,orderId+"*"+sessionId)
                                clearAll()
                            }else {
                                ShowNotification("Ödəniş zamanı xəta", "Zəhmət olmasa təkrar yoxlayın", "danger", 10)
                                salesAPI.editSalesStatus(createdDbOrderId, paymentStatus.waiting,orderId+"*"+sessionId)
                                setPaymentSuccess(false)
                                setVisibleModal(true)
                                clearAll()
                            }
                        }
                }
                console.log('next Step is Check')
            }else{
                setCurrentStatus(pageStatus.notSended)
                clearAll()
            }
        }else{

            console.log('first Step is Sending')
        }
    },[])


    let productName
    if(productType===2){
        productName="CONFERENCE"
    }else if(productType===3){
        productName="WEBINAR"
    }else if(productType===1){
        productName="TRAINING"
    }
    const [token,setToken]=useState();
    const [isExpiredToken,setIsExpiredToken]=useState(true);
    let refRecapture=useRef()
    const handleToken = (token) => {
        // setForm((currentForm) => {
        //     return {...currentForm, token }
        // })
        // console.log("Captcha value:", token);
        setToken(token)
        if (token === null) //alert("expired")
            setIsExpiredToken(true)
        else setIsExpiredToken(false)

    }
    useEffect(()=>{
            // alert(token)
            // console.log("is token expired:"+isExpiredToken)
        }
        ,[isExpiredToken])

    const handleExpire = () => {
        // setForm((currentForm) => {
        //     return {...currentForm, token: null }
        // })
        alert("expired")
        // console.log("Captcha expired");
    }
    var now = new Date();
    dateFormat.masks.myForm='yyyy-mm-dd HH:MM:ss';

    let toDay=dateFormat(now, "myForm")
    // dateFormat(now, "isoDateTime")
    // dateFormat(now, "myForm")
    const arraySingleProduct=[productSelectedd]
    useEffect(()=>{
        // console.log(productSelectedd)
        // console.log(arraySingleProduct)
        // console.log("productPrice")
        // console.log(dateFormat(now, "myForm"))
    })
    const validate = Yup.object({
        name: Yup.string()
            .min(3, 'Minimum 3 simvol')
            .max(25, 'Maksimum 25 simvol')
            .required('Ad daxil olunmalıdır'),
        phone: Yup.string()
            .matches(/^(\+)([0-9]{12,14})$/,'məs: +994557770077')
            .required('Telefon nömrəsi daxil olunmalıdır'),
        profession: Yup.string()
            .min(4, 'Minimum 4 simvol')
            .max(25, 'Maksimum 25 simvol')
            .required('İxtisas daxil olunmalıdır'),
        workplace: Yup.string()
            .min(4, 'Minimum 4 simvol')
            .max(25, 'Maksimum 25 simvol')
            .required('İş yeri daxil olunmalıdır'),
        passport: Yup.string()
            .matches(/^([A-Za-z0-9]{9,11})$/, 'məs: AZE12345678 və ya AA1234567')
            .required('Şəxsiyyət nömrəsi daxil olunmalıdır'),
        email: Yup.string()
            .email(  'məs: address@mail.com')
            .required('Email daxil olunmalıdır'),
    });
    let productInfo=arraySingleProduct.map(item=>{
        // let Day="21"
        let Day=item.startDate.substring(0,10).substring(8,10)
        let Year=item.startDate.substring(0,4)
        // let Year="2021"
        let Month=item.startDate.substring(0,7).substring(5,7)
        // let Month="06"
        let yearAndMont= Month+"-"+Year;
        let image1=fileUrl+productSelectedd.imgurl
        // let image1="https://api.medicschool.az/"+item.imgUrl
        return(
            <Formik
                initialValues={{
                    name: '',
                    phone: '',
                    profession: '',
                    workplace: '',
                    passport: '',
                    email: ''
                }}
                className="single-post-content"
                validationSchema={validate}
                onSubmit={ async (formValues)=> {
                    debugger
                    console.log(formValues)

                    if(isExpiredToken){
                        ShowNotification("Check ReCapcha","ReCapcha timed out, check again!","danger",2000)
                    }else {
                        if(parseFloat(productPrice) === 0){
                            console.log("salalsa")
                                let salesAPIResponse= await salesAPI.addSales(formValues.name,formValues.email,
                                formValues.profession, formValues.workplace, formValues.phone,formValues.passport,
                                paymentStatus.purchased, productType,productId ,parseFloat(productPrice),toDay)

                            if(salesAPIResponse !== null){
                                ShowNotification("Uğurlu tranzaksiya", "Uğurla həyata keçirildi", "success", 10)
                                setPaymentSuccess(true)
                                setVisibleModal(true)
                                clearAll()
                            }
                        }else{
                            let salesAPIResponse= await salesAPI.addSales(formValues.name,formValues.email,
                                formValues.profession, formValues.workplace, formValues.phone,formValues.passport,
                                paymentStatus.waiting,productType,productId ,parseFloat(productPrice),toDay)

                            if(salesAPIResponse !== undefined || salesAPIResponse !== null){
                                debugger
                                saveToStorage("createdOrderId",salesAPIResponse.id)
                            }
                            // setCreatedDbOrderId

                            let response = await kapitalAPI.addOrCheckPaymant(PurchaseForm())
                            debugger
                            if(response !== undefined || response !== null){
                                setOrderId(response.Response.Order.OrderID)
                                saveToStorage("orderId",response.Response.Order.OrderID)
                                setSessionId(response.Response.Order.SessionID)
                                saveToStorage("sessionId",response.Response.Order.SessionID)
                            }
                            let urlForNextStep = setUrlForPayment(response.Response.Order.OrderID,response.Response.Order.SessionID)
                            OpenPaymantWindow(urlForNextStep)
                        }
                    }
                }}
                >
                {props => (
                    <div>
                        <div className="yazi-sekil"
                             style={{marginTop:"5rem"}}
                        >
                            <img
                                // width="1200"
                                // height="1006"
                                style={{width:"920px",  height:"620px"}}
                                 src={image1}
                                // onClick={()=>{
                                //    setVisibleModal(true)
                                // }}
                                 className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                 alt="" loading="lazy"/>
                        </div>
                        <h1>{item.title}</h1>
                        <div className="yazi-meta">
                            <div className="yazi-tarix">{Day+" "+Month+" "+Year}</div>
                            <div className="yazi-kateqoriya">
                                <a href="#" itemProp="articleSection">{productName}</a></div>
                        </div>
                        <div className="yazi-metn">
                            {HTMLReactParser(item.content)}
                        </div>
                        <div className="register-form">
                            <h3>{item.title}</h3>
                            <Form>
                                <div className="form-group">
                                    <TextField label="Ad soyad"
                                               name="name"
                                               type="text"
                                    />
                                    <TextField label="Telefon"
                                               name="phone"
                                               type="text"
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField label="İxtisas"
                                               name="profession"
                                               type="text"
                                    />
                                    <TextField label="İş yeri"
                                               name="workplace"
                                               type="text"
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField label="Şəxsiyyət vəsiqəsinin seriyası"
                                               name="passport"
                                               type="text"
                                    />
                                    <TextField label="Email"
                                               name="email"
                                               type="text"
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="form-element form-50 form-select">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <ReCaptchaV2
                                                sitekey={SiteKey}
                                                // sitekey={process.env.REACT_APP_SITE_KEY}
                                                ref={refRecapture}
                                                onChange={handleToken}
                                                onExpire={handleExpire}
                                            />
                                        </div>

                                        {/*    <label htmlFor="status">Status</label>*/}
                                        {/*    <select name="status" id="status">*/}
                                        {/*        <option value="">---Seçim edin---</option>*/}
                                        {/*        <option value="telebe">Tələbə (Aid deyil)</option>*/}
                                        {/*        <option value="rezident">Rezident</option>*/}
                                        {/*        <option value="hekim">Həkim</option>*/}
                                        {/*        <option value="qeyri">Digər (Aid deyil)</option>*/}
                                        {/*    </select>*/}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div>
                                        <button
                                            type="submit"
                                            onClick={()=>{setCurrentStatus(pageStatus.Sended)}}
                                            className="btn btn-primary"
                                        >Göndər
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                )}
            </Formik>)})

        return (<div className="container col-8 col-offset-4">
                {productInfo}
            <div
            className="bg-light my-2"
            >
                <Modall visibility={visibleModal}
                    isSuccess={isPaymentSuccess}
                />
                <div className="heading-section fadeInUp
                                    ftco-animated d-flex justify-content-center pt-3">
                    <h3>Məhsul ilə bağlı Sosial Şəbəkələrdən müraciət edin</h3>
                </div>
                <div className={style.sosials_main}>
                    <div className={style.wrapper}>
                        <div className={`${style.icon} ${style.facebook} `}
                        onClick={()=>{
                            window.open("https://instagram.com/medicschool_","_blank")
                        }}
                        >
                            <div className="tooltip">
                                Facebook
                            </div>
                            <span><i className="fab fa-facebook-f"></i></span>
                        </div>
                       <div className={`${style.icon} ${style.youtube} `}
                             onClick={()=>{
                                 window.open("https://youtube.com/channel/UCrp7V6IrrzMgFevajTcm6TQ","_blank")

                             }}>
                            <div className="tooltip">
                                Youtube
                            </div>
                            <span><i className="fab fa-youtube"></i></span>
                        </div>
                        <div className={`${style.icon} ${style.instagram} `}
                             onClick={()=>{
                                 // alert("fb")
                                 window.open("https://instagram.com/medicschool_","_blank")
                             }}>
                            <div className="tooltip">
                                Instagram
                            </div>
                            <span><i className="fab fa-instagram"></i></span>
                        </div>
                        <div className={`${style.icon} ${style.telegram} `}
                             onClick={()=>{
                                 window.open("https://t.me/medicschool_tv","_blank")
                             }}>
                            <div className="tooltip">
                                Telegram
                            </div>
                            <span><i className="fab fa-telegram"></i></span>
                        </div>
                        <div className={`${style.icon} ${style.whatsapp} `}
                             onClick={()=>{
                                 // alert("fb")
                                 window.open(`https://wa.me/994513406362?text=Salam MedicSchooldan maraqlı olan mehsul - Id: ${productSelectedd.id} Ad: ${productSelectedd.title}`,"_blank")

                             }}>
                            <div className="tooltip">
                                Whatsapp
                            </div>
                            <span><i className="fab fa-whatsapp"></i></span>
                        </div>

                    </div>
                </div>
            </div>
            </div>)
}
