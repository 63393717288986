import React from 'react';
// import image1 from "../assets/products/1.jpg"
import style from "./Products.module.css"
import "./style1.css"
import {NavLink} from "react-router-dom";
import HTMLReactParser from 'html-react-parser';
import {fileUrl} from "../../api/api";

export const Products = (props) => {
// debugger
    // console.log(props.match.params)
    const productsList=props.products.map((product) =>{
        if(product.productType == props.productType){
            debugger
            let Day=product.startDate.substr(8,2)
            let Year=product.startDate.substr(0,4)
            let Month=product.startDate.substr(5,2)
            let yearAndMont= Month+"-"+Year;
            let title=product.title.substring(0,46)
            let content=product.content.substring(0,154)
            let image1=fileUrl+product.imgurl

//PDOException: SQLSTATE[22007]: Invalid datetime format: 1292 Incorrect datetime value: '2023-01-24 undefined' for column `u345723074_medic_school`.`products`.`startDate` at row 1 in
            return (
                <div className="product" key={product.id}>
                    <div className="product-inner">
                        <div className="product-img">
                            <NavLink to={`/product/${product.id}`}
                                     aria-label="Elmi araşdırmaların aparılması üzrə təlim+SPSS">
                                <img
                                    style={{width:"350px",  height:"250px"}}
                                     src={image1}
                                    // src={product.image ?product.image: image1}
                                     className="attachment-kesim size-kesim wp-post-image" alt=""
                                     loading="lazy"/>
                                <div className="product-overlay"></div>
                                <div className="product-date">
                                    <div className="product-day fontbold">{Day}</div>
                                    <div className="product-month">{yearAndMont}</div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="product-details">
                            <h2 className="product-header">
                                <NavLink  to={`/product/${product.id}`}>
                                    {title}
                                </NavLink>
                            </h2>
                            <div className="product-meaning">
                                {HTMLReactParser(content)}
                            </div>
                            <div className="product-button fontbold">
                                <NavLink  to={`/product/${product.id}`}>
                                    Ətraflı
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    })
    return (
        <div className={style.main}>
            {props.productName}
            <div id="content-wrap" className="container">
                <div id="primary" className="content-area">
                    <div id="content"
                         className="site-content"
                         role="main">
                        <div className="products">
                            {productsList}
                        </div>
                        <div className="pages">
                            <span aria-current="page"
                                  className="page-numbers current">1</span>
                            <a className="page-numbers"
                               href="#">2</a>
                            <a className="next page-numbers"
                               href="#"><i
                                className="fa fa-chevron-right" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
