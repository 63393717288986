import React, {useEffect, useState} from 'react';
// import "./style.css"
import {Product} from "./Product";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {compose} from "redux";
import {getProducts} from "../../redux/ProductsReducer";

const mapStateToProps=(state)=>({
    products:state.productsPage.products,
})

const ProductContainer = (props) => {
    const productId=props.location.pathname.replace("/product/","")

    const [productSelected,setProductSelectted]=useState()

    // useEffect(()=>{
    //     console.log(productId)
    //     console.log(productSelected)
    //     props.getProducts()
    // },[])

    // useEffect( ()=>{
    //     setProducts(props.products)
    //     if(props.products!=null){
    //         let productSelectedd=props.products.filter(el=>el.id===productId)[0]
    //         setProductSelectted(productSelectedd)
    //     }
    // },[props.products])

    const [toRenderProduct,setRenderProduct]=useState(false)
    const [productsN,setProducts]=useState(props.products);
    useEffect( ()=>{
        // console.log(props.match.params.productId)
        window.scrollTo(0, 0)
        props.setIsHome(false)
        props.getProducts()
    },[])

    useEffect( ()=>{
        setProducts(props.products)

    },[props.products])
    useEffect(()=> {
            let productSelectedd=props.products.filter(el=>el.id===parseInt(productId))[0]
             if(productSelectedd != undefined) setRenderProduct(true)
    },[productsN])


    return (
        <div>
            {toRenderProduct && <Product
                productId={productId}
                products={productsN}
                {...props}
            />}
        </div>
    );
};

export default compose(
    withRouter,
    connect(mapStateToProps,
        {getProducts}
    )
)(ProductContainer);
