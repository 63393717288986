import React, {useEffect, useState} from 'react';
import {Blog} from "./Blog";
import {compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {getBlogs} from "../../redux/blogReducer";

let mapStateToProps =(state)=>({
    blogs:state.blogPage.blogs,
})

 const BlogContainer = ({blogs,getBlogs,setIsHome,...props}) => {
    const [blogsN,setBlogs]=useState(blogs);

    useEffect( ()=>{
        getBlogs(0,20)
        setIsHome(false)
        window.scrollTo(0, 0)
    },[])

    useEffect( ()=>{
        setBlogs(blogs)
    },[blogs])
    return (
        <div>
            {blogsN.length>0 &&
                <Blog blogs={blogsN}/>
            }
        </div>
    );
};
export default compose(
    withRouter,
    connect(mapStateToProps, {getBlogs})
)(BlogContainer);