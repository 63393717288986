import React, {useRef} from 'react';
// import './style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {Header} from "./component/Header/Header";
import {Footer} from "./component/Footer/Footer";
import {Home} from "./pages/Home/Home";
import 'react-notifications-component/dist/theme.css'
import {compose} from "redux";
import {Redirect, useHistory, withRouter} from "react-router";
import {useEffect, useState} from "react";
import ReactNotification from "react-notifications-component";
import { Route, Switch} from 'react-router-dom';
import NotExistPage from "./pages/NotExistPage/NotExistPage";
import {connect} from "react-redux";
import ProductsContainer from "./pages/Products/ProductsContainer";
import BlogContainer from "./pages/Blog/BlogContainer";
import ProductContainer from "./pages/Product/ProductContainer";
import BlogSingleContainer from "./pages/BlogSingle/BlogSingleContainer";


let mapStateToProps =(state)=>({
    pageLoaded:state.authPage.users,
    isAuth:state.authPage.isAuth,

})
function App({pageLoaded,isAuth,...props}) {
    const scrollToRef = (ref) => {
        debugger
        window.scrollTo(0, ref.current.offsetTop)
    }

    const [loginPageLoaded, setLoginPageLoaded] =useState(false);
    const [isHome, setIsHome] =useState(true); // for link system

    useEffect(()=>{
        if(pageLoaded){
            setLoginPageLoaded(true)
        }
        else setLoginPageLoaded(false)
    },[])

    return (
        <div className="App">
            <ReactNotification/>
            <Header setLoginPageLoaded={setLoginPageLoaded}
                    isHome={isHome}
                    props={props}
                    setIsHome={setIsHome}/>
            <Switch>
                <Route path="/blogSingle/:blogId?(\d)"
                       render={(props) => <BlogSingleContainer{...props}
                                                              setIsHome={setIsHome}/>}/>
                <Route exact path='/blog'
                       render={() => <BlogContainer
                           setIsHome={setIsHome}/>}/>
                <Route path="/products/:productId(webinar|training|conference)"
                       render={(props) => <ProductsContainer {...props}
                                                             setIsHome={setIsHome}/>}/>
                <Route path="/product/:productId?(\d)"
                       render={(props) => <ProductContainer{...props}
                                                           setIsHome={setIsHome}/>}/>
                {/*(^(webinar|training|conference)$)*/}
                <Route exact path='/' render={() => <Home
                    setIsHome={setIsHome}
                />
                }
                />
                <Route exact path='*' render={() =>
                    <Redirect to='/'/>
                // /    <NotExistPage/>
                }

                />
            </Switch>
            {!loginPageLoaded &&<Footer
                setIsHome={setIsHome}/>}
        </div>
    );
}

export default compose(
    withRouter,
    connect(mapStateToProps, )
)(App);
