// import React from 'react';
// import { ErrorMessage, useField } from 'formik';
//
// export const TextField = ({ label,isReadonly,enableLabel, ...props }) => {
//   const [field, meta] = useField(props);
//
//   return (
//     <div
//         className={`${enableLabel && 'mb-2'}`}
//     >
//       {enableLabel && <label htmlFor={field.name}>{label}</label>}
//       <input className={`form-control shadow-none ${meta.touched && meta.error && 'is-invalid'}`}
//         {...field} {...props}
//         autoComplete="off"
//              disabled={isReadonly}
//       />
//       <ErrorMessage component="div" name={field.namemaile} className="error" />
//     </div>
//   )
// }

import React from 'react';
import style from './TextField.Module.css';
import { ErrorMessage, useField } from 'formik';

export const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
      <div
          className="form-element form-50"
      >
        <label htmlFor={field.name}>{label}</label>
        <input
            className={` ${meta.touched && meta.error && 'is-invalid'}`}
            // className={`form-control shadow-none ${meta.touched && meta.error && 'is-invalid'}`}
            {...field} {...props}
            autoComplete="off"
        />

        <ErrorMessage component="div" name={field.name} className={style.error} />
      </div>
  )
}
