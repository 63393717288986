import React, {useEffect, useState} from 'react';
import {Products} from "./Products";
import {compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {getProducts} from "../../redux/ProductsReducer";

let mapStateToProps =(state)=>({
    products:state.productsPage.products,
})

const ProductsContainer = (props) => {
    let productType
    const productName=props.match.params.productId.toUpperCase();

    if(productName==="CONFERENCE"){
        productType=2;
    }else if(productName==="WEBINAR"){
        productType=3;
    }else if(productName==="TRAINING"){
        productType=1;
    }

    const [productsN,setProducts]=useState(props.products);
    const [toRenderProduct,setRenderProduct]=useState(false)
    useEffect( ()=>{
        console.log(props.match.params.productId)
        window.scrollTo(0, 0)
        props.setIsHome(false)
        props.getProducts()
    },[])

    useEffect( ()=>{
        setProducts(props.products)
    },[props.products])

    useEffect( ()=>{
            let productSelectedd=productsN.filter(el=>el.productType===parseInt(productType))[0]
            if(productSelectedd != undefined) setRenderProduct(true) 
    },[productsN])

    return (
        <div>
            {toRenderProduct && <Products
                products={productsN}
                productName={productName}
                productType={productType}
            />}
        </div>
    )
}

export default compose(
    withRouter,
    connect(mapStateToProps,
        {getProducts}
    )
)(ProductsContainer);
