import React from 'react';
import {blogAPI} from "../api/api";
import ShowNotification from "../components/common/Notification/ShowNotification";

const SET_BLOG_DATA = 'SET_BLOG_DATA';
export const setBlogData = (payload) => {
    return {
        type: SET_BLOG_DATA,
        payload: payload
    }
}
const SET_SELECTED_BLOG_DATA = 'SET_SELECTED_BLOG_DATA';
export const setSelectedBlogData = (payload) => {
    return {
        type: SET_SELECTED_BLOG_DATA,
        payload: payload
    }
}



let initialState = {
   blogs:[
       // {
       //     "content": "content 0",
       //     "created_at": "2021-07-21T15:35:35.000000Z",
       //     "id": 1,
       //     "imgUrl": "100",
       //     "status": 0,
       //     "title": "title 0",
       //     "youtubeLink": "1010",
       // }

   ],
    selectedBlog:null,
    totalPages:4,
    totalElements:31,
    size:10
}

let blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BLOG_DATA:
            return {
                ...state,
                blogs:action.payload.blogs,
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements,
                size: action.payload.size,
            }
        case SET_SELECTED_BLOG_DATA:
            return {
                ...state,
                selectedBlog:action.payload,
            }
        default :
            return state;
    }
}

export const getBlogs = (page,size) => {
    return async (dispatch) => {
        debugger
        let response = await blogAPI.getBlogs(page,size);
        if (response !=undefined  && response.status===200) {
            dispatch(setBlogData(response.data));
        }
        // else {
        //     debugger
        //     debugger
        //     let errorMessage='Server: '+response.response.data;
        //     ShowNotification("Error",errorMessage,"danger",3000)
        // }
    }
}

export const getBlogById = (id) => {
    return async (dispatch) => {
        debugger
        let response = await blogAPI.getBlogById(id);
        if (response.status===200) {
            dispatch(setSelectedBlogData(response.data.blog));
        }else {
            debugger
            debugger
            let errorMessage='Server: '+response.response.data;
            ShowNotification("Error",errorMessage,"danger",3000)
        }
    }
}
// export const addDeviceTh = (device) => {
//     return async (dispatch) => {
//         let response = await deviceApi.addDevice(device);
//         if (response.status===200) {
//             debugger
//             ShowNotification("Succes","Device added successful", "success")
//             // dispatch(getDevicesTh(0,10));
//         } else {
//             let errorMessage='Server: '+response.response.data;
//             ShowNotification("Error",errorMessage,"danger",3000)
//         }
//     }
// }
// export const updateDeviceTh = (device) => {
//     return async (dispatch) => {
//         debugger
//         let response = await deviceApi.updateDevice(device);
//         if (response.status === 200) {
//             // dispatch(updateGroupData(response.data));
//             ShowNotification("Succes","Device updated successful", "warning")
//
//             dispatch(getDevicesTh(0,10));
//         } else {
//             let errorMessage='Server: '+response.response.data;
//             ShowNotification("Error",errorMessage,"danger",3000)
//         }
//     }
// }
// export const deleteDeviceTh = (locationId) => {
//     return async (dispatch) => {
//         let response = await deviceApi.deleteDevice(locationId );
//         if (response.status === 200) {
//             // dispatch(deleteBuildingData(response.content));
//             ShowNotification("Succes","Device deleted successful", "danger")
//
//             dispatch(getDevicesTh(0,10));
//         }else {
//             let errorMessage='Server: '+response.response.data;
//             ShowNotification("Error",errorMessage,"danger",3000)
//         }
//     }
// }
export default blogReducer;
