import React from 'react';
import style from "./Blog.module.css"
// import image1 from "../assets/products/1.jpg";
import {NavLink} from "react-router-dom";
import HTMLReactParser from 'html-react-parser';
import {fileUrl} from "../../api/api";


export const Blog = ({blogs}) => {

    // const blogId=props.match.params.blogId;
    const blogsList=blogs.map((blog)=> {
        let Day=blog.created_at.substring(0,10).substring(8,10)
        let Year=blog.created_at.substring(0,4)
        let Month=blog.created_at.substring(0,7).substring(5,7)
        let yearAndMont= Month+"-"+Year;
        let title=blog.title.substring(0,46)
        let content=blog.content.substring(0,154)
        let image1=fileUrl+blog.imgUrl
        // let image1="https://api.medicschool.az/"+blog.imgUrl
        return (
            <div className="product" key={blog.id}>
                <div className="product-inner">
                    <div className="product-img">
                        <NavLink to={`blogSingle/${blog.id}`}
                                 // aria-label="Elmi araşdırmaların aparılması üzrə təlim+SPSS"
                        >
                            <img
                                // width="500"
                                // height="350"
                                style={{width:"350px",  height:"250px"}}
                                 src={image1}
                                // src={blog.image ?blog.image: image1}
                                 className="attachment-kesim size-kesim wp-post-image" alt=""
                                 loading="lazy"/>
                            <div className="product-overlay"></div>
                            <div className="product-date">
                                <div className="product-day fontbold">{Day}</div>
                                <div className="product-month">{yearAndMont}</div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="product-details">
                        <h2 className="product-header">
                            <NavLink to={`blogSingle/${blog.id}`}>
                                {title}
                            </NavLink>
                        </h2>
                        <div className="product-meaning">
                            {HTMLReactParser(content)}
                        </div>
                        <div className="product-button fontbold">
                            <NavLink to={`blogSingle/${blog.id}`}>
                                Ətraflı
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <div className={style.main}>
            <div id="content-wrap" className="container">
                <div id="primary" className="content-area">
                    <div id="content" className="site-content" role="main">
                        <div className="products">
                            {blogsList}
                        </div>
                        <div className="pages">
                            <span aria-current="page"
                                  className="page-numbers current">1</span>
                            <a className="page-numbers"
                               href="#">2</a>
                            <a className="next page-numbers"
                               href="#"><i
                                className="fa fa-chevron-right" aria-hidden="true"></i></a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};
