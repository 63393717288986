import * as React from 'react';
import {NavLink} from "react-router-dom";
import {Link } from 'react-scroll'
import {useEffect, useRef, useState} from "react";
import style from "../../pages/Home/Home.module.css";

export const Header = ({props,isHome}) => {
    useEffect(()=>{
        console.log(isHome)
    })
    let ShowHideMenuRef=useRef();
    let [showMenu, setShowMenu] =useState("")

    const CheckAndSetMenuShow= ()=>{
        if(showMenu == "")
            setShowMenu("show")
        else setShowMenu("")
    }
    // const scrollToSection = () => {
    //     scroller.scrollTo("your_css_class_here", {
    //         duration: 800,
    //         delay: 0,
    //         smooth: "easeInOutQuart",
    //     });
    // };
    return (
        <div>
            <div
                className={style.sosial}
            >
                <div className={style.wrapper}>
                    <div className={`${style.icon} ${style.whatsapp}`}
                         onClick={()=>{
                             window.open(`https://wa.me/994507551135?text=Salam MedicSchooldan müraciət edirəm`,"_blank")

                         }}>
                        <div className={style.tooltip}>
                            Whatsapp
                        </div>
                        <span><i className="fab fa-whatsapp"></i></span>
                    </div>
                </div>
            </div>
            {/*<div className="py-1 bg-primary top">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row no-gutters d-flex align-items-start align-items-center px-md-0">*/}
            {/*            <div className="col-lg-12 d-block">*/}
            {/*                <div className="row d-flex">*/}
            {/*                    <div className="col-md pr-4 d-flex topper align-items-center"*/}
            {/*                    style={{maxHeight:"25px"}}*/}
            {/*                    >*/}
            {/*                        <div className="icon mr-2 d-flex justify-content-center align-items-center"><span*/}
            {/*                            className="icon-phone2"></span></div>*/}
            {/*                        <span className="text">+ 994505000000</span>*/}
            {/*                    </div>*/}
            {/*                    <div className="col-md pr-4 d-flex topper align-items-center"*/}
            {/*                         style={{maxHeight:"25px"}}*/}
            {/*                    >*/}
            {/*                        <div className="icon mr-2 d-flex justify-content-center align-items-center"*/}
            {/*                        ><span*/}
            {/*                            className="icon-paper-plane"></span></div>*/}
            {/*                        <span className="text">youremail@email.com</span>*/}
            {/*                    </div>*/}
            {/*                    <div*/}
            {/*                        className="col-md-5 pr-4 d-flex topper align-items-center text-lg-right justify-content-end"*/}
            {/*                        style={{maxHeight:"25px"}}*/}
            {/*                    >*/}
            {/*                        <p className="mb-0 register-link">*/}
            {/*                            /!*<a href="/#" className="mr-3">Sign Up</a>*!/*/}
            {/*                            /!*<NavLink to={'/'}*!/*/}
            {/*                            /!*         onClick={()=>{*!/*/}

            {/*                            /!*         }}*!/*/}
            {/*                            /!*         className="brand-link">Ana Səhifə</NavLink>*!/*/}
            {/*                        <NavLink*/}
            {/*                            // to={'/login'}*/}
            {/*                                 to={{pathname:"http://localhost:3001"}}*/}
            {/*                                 target="_parent"*/}

            {/*                                 // onClick={()=>{*/}
            {/*                                 //     setLoginPageLoaded(true)*/}
            {/*                                 // }}*/}
            {/*                                 className="brand-link">Login Olun</NavLink>*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <nav
                className="
                    navbar navbar-expand-lg navbar-dark
                    ftco_navbar bg-dark ftco-navbar-light site-navbar-target scrolled awake
                "
                id="ftco-navbar">
                <div className="container">
                    {/*<a className="navbar-brand "*/}
                    {/*   href="/"*/}
                    {/*>Medic School</a>*/}
                    {isHome &&
                    <Link className="navbar-brand"
                        to="home-section"
                          spy={true}
                          smooth={true}
                          duration={500}
                        >Medic School</Link>}
                    {!isHome  &&
                    <NavLink to={"/"}
                          className="navbar-brand">
                        <span>Medic School</span>
                    </NavLink>
                    }
                    <button className="navbar-toggler
                                        js-fh5co-nav-toggle
                                        fh5co-nav-toggle
                                        collapsed"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#ftco-nav"
                                        aria-controls="ftco-nav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        onClick={(e)=>{
                                            CheckAndSetMenuShow()
                                        }}
                    >
                        {/*<span className="oi oi-menu"></span> */}

                        <i className="text-primary fas fa-bars"></i>
                        {/*<p className="text-primary">Menu</p>*/}
                    </button>
                    {/*<button className="navbar-toggler"*/}
                    {/*        type="button"*/}
                    {/*        data-toggle="collapse"*/}
                    {/*        data-target="#ftco-nav"*/}
                    {/*        aria-controls="ftco-nav"*/}
                    {/*        aria-expanded="false"*/}
                    {/*        aria-label="Toggle navigation">*/}
                    {/*    <span className="navbar-toggler-icon"></span>*/}
                    {/*</button>*/}
                    <div className={`navbar-collapse collapse ${showMenu}`}
                         // id="ftco-nav"
                         ref={ShowHideMenuRef}
                    >
                        <ul className="navbar-nav nav ml-auto text-center">
                            <li className="nav-item">
                                {!isHome &&
                                <NavLink to={"/"} className="nav-link"
                                         onClick={(e)=>{
                                             CheckAndSetMenuShow()
                                         }}
                                >
                                    <span>Ana Səhifə</span>
                                </NavLink>}

                                {/*<a href="#home-section" className="nav-link"></a>*/}
                                {isHome &&
                                <Link to="home-section"
                                       spy={true}
                                       smooth={true}
                                       duration={500}
                                       className="nav-link"
                                      onClick={(e)=>{
                                          CheckAndSetMenuShow()
                                      }}
                                >
                                    <span>Ana Səhifə</span>
                                </Link>}
                                {/*<NavLink to='/#home-section' className="nav-link"><span>Ana Səhifə</span></NavLink>*/}
                            </li>
                            <li className="nav-item">
                                {/*{!isHome && <NavLink to={"/"} className="nav-link"><span>Haqqımızda</span></NavLink>}*/}

                                {/*<a href="#about-section"  className="nav-link"><span>Haqqımızda</span></a>*/}
                                {isHome && <Link to="about-section"
                                       spy={true}
                                       smooth={true}
                                       duration={500}
                                       className="nav-link"
                                                  activeClass="active"
                                                 onClick={(e)=>{
                                                     CheckAndSetMenuShow()
                                                 }}
                                       onSetActive={() => {
                                       }}>
                                    <span>Haqqımızda</span>
                                </Link>}
                            </li>
                            <li className="nav-item">
                                {/*{!isHome && <NavLink to={"/"} className="nav-link"><span>Sahələr</span></NavLink>}*/}

                                {isHome &&
                                <Link to="department-section"
                                       spy={true}
                                       smooth={true}
                                       duration={500}
                                       className="nav-link"
                                      onClick={(e)=>{
                                          CheckAndSetMenuShow()
                                      }}
                                activeClass="active">
                                    <span>Sahələr</span>
                                </Link>}
                            </li>
                            <li className="nav-item">
                                {/*{!isHome &&*/}
                                {/*<NavLink to={"/"} className="nav-link">*/}
                                {/*    <span>Həkimlər</span>*/}
                                {/*</NavLink>}*/}

                                {isHome &&
                                    <Link to="doctor-section"
                                          spy={true}
                                          smooth={true}
                                          duration={500}
                                          className="nav-link"
                                          activeClass="active"
                                          onClick={(e)=>{
                                              CheckAndSetMenuShow()
                                          }}
                                          onSetActive={() => {
                                          }}>
                                        <span>Həkimlər</span>
                                    </Link>}
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    // to={'/login'}
                                    to={'/blog'}
                                    // onClick={()=>{
                                    //     setLoginPageLoaded(true)
                                    // }}
                                    onClick={(e)=>{
                                        CheckAndSetMenuShow()
                                    }}
                                    className="nav-link">
                                    <span>Blog</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                {/*<a href="#blog-section" className="nav-link"><span>Blog</span></a>*/}
                                <NavLink
                                    // to={'/login'}
                                    to={'/products/conference'}
                                    onClick={(e)=>{
                                        CheckAndSetMenuShow()
                                    }}
                                    // onClick={()=>{
                                    //     setLoginPageLoaded(true)
                                    // }}
                                    className="nav-link">
                                    <span>Konfrans</span>
                                    </NavLink>
                            </li>
                            <li className="nav-item">
                                {/*<a href="#blog-section" className="nav-link"><span>Blog</span></a>*/}
                                <NavLink
                                    // to={'/login'}
                                    to={'/products/webinar'}
                                    onClick={(e)=>{
                                        CheckAndSetMenuShow()
                                    }}
                                    // onClick={()=>{
                                    //     setLoginPageLoaded(true)
                                    // }}
                                    className="nav-link">
                                    <span>Vebinar</span>
                                    </NavLink>
                            </li>
                            <li className="nav-item">
                                {/*<a href="#blog-section" className="nav-link"><span>Blog</span></a>*/}
                                <NavLink
                                    // to={'/login'}
                                    to={'/products/training'}
                                    // onClick={()=>{
                                    //     setLoginPageLoaded(true)
                                    // }}
                                    onClick={(e)=>{
                                        CheckAndSetMenuShow()
                                    }}
                                    className="nav-link">
                                    <span>Təlim</span>
                                    </NavLink>
                            </li>
                            <li className="nav-item">
                                {/*<a href="#contact-section" className="nav-link"><span> Əlaqə</span></a>*/}

                                {/*{!isHome &&*/}
                                {/*<NavLink to={"/"} className="nav-link">*/}
                                {/*    <span>Əlaqə</span>*/}
                                {/*</NavLink>}*/}
                                {isHome &&
                                <Link to="contact-section"
                                      spy={true}
                                      smooth={true}
                                      duration={500}
                                      className="nav-link"
                                      activeClass="active"
                                      onClick={(e)=>{
                                          CheckAndSetMenuShow()
                                      }}
                                      onSetActive={(e) => {

                                      }}
                                >
                                    <span>Əlaqə</span>
                                </Link>}
                            </li>
                            {/*<li className="nav-item cta mr-md-2">*/}
                            {/*    <a href="#" className="nav-link">Appointment</a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};
