

import {authAPI} from "../api/api";
import {readFromStorage, saveToStorage} from "../api/storageApi";
import { isExpired, decodeToken } from "react-jwt";
import ShowNotification from "../components/common/Notification/ShowNotification"
import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';

const SET_USER_DATA = 'SET_USER_DATA';
const LOGOUT_USER = 'LOGOUT_USER';
const IS_LOADING = 'IS_LOADING';
const PAGE_LOADED = 'PAGE_LOADED';

export const setUserData = (id, name, email,isAuth) => {
    return {
        type: SET_USER_DATA,
        payload: {
            id, name, email,isAuth}
    }
}

export const logOutUser =(payload)=>
{
    return{
        type:LOGOUT_USER,
        payload:null
    }
}
export const PageLoadedTrue =(pageLoaded)=>
{
    return{
        type:PAGE_LOADED,
        pageLoaded
    }
}

export const isLoading = (isLoadingStatus) => {
    return {
        type: IS_LOADING,
        isLoadingStatus
    }
}


let  username=  readFromStorage("username") ? readFromStorage("username") : "No username"
let  accessToken= readFromStorage("accessToken") ? readFromStorage("accessToken") :  null
let  isAuth= readFromStorage("isAuth") ? readFromStorage("isAuth") : false


let initialState = {
    id:"",
    name:"",
    email:"",
    isAuth: isAuth,
    pageLoaded: false,
    isLoadingStatus: false,
}

let authReducer = (state=initialState,
                   action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...action.payload
            }

        case LOGOUT_USER:
            return {
                state:null
            }
        case PAGE_LOADED:
            debugger
            return {
                ...state,
                pageLoaded:action.pageLoaded
            }

        case IS_LOADING:
            return {
                ...state,
                isLoadingStatus: action.isLoadingStatus
            }
        default :
            return state;
    }
}


export const loginMe = (username, password) => {
    return async (dispatch) => {
        let response = await authAPI.login(username, password );
        if (response.status===200){
            // let cc=read_cookie('jwt')
            // console.log(cc)
            dispatch(getUserInfo())
            //
            // let {token}=response;
            // const myDecodedToken = decodeToken(token);
            // const isMyTokenExpired = isExpired(token);
            // ShowNotification("Succes","Your Logged With Success","info")
            // if(!isMyTokenExpired){
            //     let {first_name, last_name, idd, locationid, mobile,officename, role,roles,sub, username} = myDecodedToken;
            //     isAuth=true;
            //     dispatch(setUserData(first_name, last_name, idd, locationid, mobile,officename, role,roles,sub, username, token, isAuth));
            //     dispatch(isLoading(false));
            //     saveToStorage("first_name",first_name)
            //     saveToStorage("last_name",last_name)
            //     saveToStorage("idd",idd)
            //     saveToStorage("locationid",locationid)
            //     saveToStorage("mobile",mobile)
            //     saveToStorage("officename", officename)
            //     saveToStorage("role", role)
            //     saveToStorage("roles", roles !==null && roles !==undefined ? roles[0].authority:"")
            //     saveToStorage("sub", sub)

            }
        }
    }


export const logOutMe = () => {
    return (dispatch) => {
            ShowNotification("Logout","You logged out!","danger")
            dispatch(logOutUser(null))
    }
}
export const getUserInfo= () => {
    return async (dispatch) => {
        let response = await authAPI.user();
        if(response)
        {
            let {id,name,email}=response;
            isAuth=true;
            dispatch(setUserData(id,name,email,isAuth))
            saveToStorage("name",name)
            saveToStorage("email",email)
            saveToStorage("isAuth",isAuth)
            ShowNotification("Success","You logged in!","success")
        }
    }
}
export const doPageLoadedTrue = () => {
    debugger
    return (dispatch) => {
            ShowNotification("hies","You   out!","danger")
            dispatch(PageLoadedTrue(true))
    }
}

export default authReducer;
