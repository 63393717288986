import React, {useEffect, useState,useRef} from 'react';
import HTMLReactParser from 'html-react-parser';
import style from "./BlogSingle.module.css"
import "./style.css"
import {fileUrl} from "../../api/api";
import dateFormat  from "dateformat";


export const BlogSingle = (props) => {
    debugger
    const selectedBlog=props.blogs
    // let productSelectedd=props.products.filter(el=>el.id===parseInt(props.blogId))[0]
    // let blogId=productSelectedd.id
    // let productType=props.products.filter(el=>el.id===parseInt(props.productType))[0]
    // let productPrice=productSelectedd.price


    var now = new Date();
    dateFormat.masks.myForm='yyyy-mm-dd HH:MM:ss';
    // dateFormat(now, "isoDateTime")
    // dateFormat(now, "myForm")
    const arraySingleProduct=[selectedBlog]
    useEffect(()=>{
        // console.log(productSelectedd)
        // console.log(arraySingleProduct)
        console.log("productPrice")
        console.log(dateFormat(now, "myForm"))
    })

        let Day=selectedBlog.created_at.substring(0,10).substring(8,10)
        let Year=selectedBlog.created_at.substring(0,4)
        // let Year="2021"
        let Month=selectedBlog.created_at.substring(0,7).substring(5,7)
        // let Month="06"
        let yearAndMont= Month+"-"+Year;
        let image1=fileUrl+selectedBlog.imgUrl

    return (
        <div className="container col-8 col-offset-4">
            <div>
                <div className="yazi-sekil"
                     style={{marginTop:"5rem"}}
                >
                    <img
                        // width="1200"
                        // height="1006"
                        style={{width:"920px",  height:"620px"}}
                        src={image1}
                        className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                        alt="" loading="lazy"/>
                </div>
                <h1>{selectedBlog.title}</h1>
                <div className="yazi-meta">
                    <div className="yazi-tarix">{Day+" "+Month+" "+Year}</div>
                    {/*<div className="yazi-kateqoriya">*/}
                    {/*    <a href="#" itemProp="articleSection">{productName}</a>*/}
                    {/*</div>*/}
                </div>
                <div className="yazi-metn">
                    {HTMLReactParser(selectedBlog.content)}
                </div>
                <div className="register-form">
                    <h3>{selectedBlog.title}</h3>
                </div>
            </div>
        <div
            className="bg-light my-2"
        >
            <div className="heading-section fadeInUp
                                    ftco-animated d-flex justify-content-center pt-3">
                <h3>Sosial Şəbəkələrdən bizə müraciət edin</h3>
            </div>
            <div className={style.sosials_main}>
                <div className={style.wrapper}>
                    <div className={`${style.icon} ${style.facebook} `}
                         onClick={()=>{
                             window.open("https://instagram.com/medicschool_","_blank")
                         }}
                    >
                        <div className="tooltip">
                            Facebook
                        </div>
                        <span><i className="fab fa-facebook-f"></i></span>
                    </div>
                    <div className={`${style.icon} ${style.youtube} `}
                         onClick={()=>{
                             window.open("https://youtube.com/channel/UCrp7V6IrrzMgFevajTcm6TQ","_blank")

                         }}>
                        <div className="tooltip">
                            Youtube
                        </div>
                        <span><i className="fab fa-youtube"></i></span>
                    </div>
                    <div className={`${style.icon} ${style.instagram} `}
                         onClick={()=>{
                             // alert("fb")
                             window.open("https://instagram.com/medicschool_","_blank")
                         }}>
                        <div className="tooltip">
                            Instagram
                        </div>
                        <span><i className="fab fa-instagram"></i></span>
                    </div>
                    <div className={`${style.icon} ${style.telegram} `}
                         onClick={()=>{
                             window.open("https://t.me/medicschool_tv","_blank")
                         }}>
                        <div className="tooltip">
                            Telegram
                        </div>
                        <span><i className="fab fa-telegram"></i></span>
                    </div>
                    <div className={`${style.icon} ${style.whatsapp} `}
                         onClick={()=>{
                             window.open(`https://wa.me/994507551135?text=Salam MedicSchooldan maraqlı olan blog - Id: ${selectedBlog.id} Ad: ${selectedBlog.title}`,"_blank")

                         }}>
                        <div className="tooltip">
                            Whatsapp
                        </div>
                        <span><i className="fab fa-whatsapp"></i></span>
                    </div>

                </div>
            </div>
        </div>
    </div>)
}
