
import * as React from 'react';
import {NavLink} from "react-router-dom";

export const Footer = (props) => {
    return (
        <footer className="ftco-footer ftco-section img"
                // style="background-image:url(images/xfooter-bg.jpg.pagespeed.ic.QTC6RZeGfT.webp)"
        >
            <div className="overlay"></div>
            <div className="container-fluid px-md-5">
                <div className="row mb-5">
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4">
                            <h2 className="ftco-heading-2">Medic School</h2>
                            <p>Far far away, behind the word mountains, far from the countries.</p>
                            {/*<ul className="ftco-footer-social list-unstyled mt-5">*/}
                            {/*    <li className="ftco-animate fadeInUp ftco-animated">*/}
                            {/*        <a href="/#"><span className="icon-twitter"></span></a>*/}
                            {/*    </li>*/}
                            {/*    <li className="ftco-animate fadeInUp ftco-animated">*/}
                            {/*        <a href="/#"><span className="icon-facebook"></span></a>*/}
                            {/*    </li>*/}
                            {/*    <li className="ftco-animate fadeInUp ftco-animated">*/}
                            {/*        <a href="/#"><span className="icon-instagram"></span></a>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4 ml-md-4">
                            <h2 className="ftco-heading-2">Sahə</h2>
                            <ul className="list-unstyled">
                                <li><a href="/#"><span className="icon-long-arrow-right mr-2"></span>Neurology</a></li>
                                <li><a href="/#"><span className="icon-long-arrow-right mr-2"></span>Opthalmology</a>
                                </li>
                                <li><a href="/#"><span className="icon-long-arrow-right mr-2"></span>Nuclear
                                    Magnetic</a></li>
                                <li><a href="/#"><span className="icon-long-arrow-right mr-2"></span>Surgical</a></li>
                                <li><a href="/#"><span className="icon-long-arrow-right mr-2"></span>Cardiology</a></li>
                                <li><a href="/#"><span className="icon-long-arrow-right mr-2"></span>Dental</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4 ml-md-4">
                            <h2 className="ftco-heading-2">Links</h2>
                            <ul className="list-unstyled">
                                <li><a href="/#">
                                    <span className="icon-long-arrow-right mr-2"></span>
                                    Ana Səhifə
                                    {/*Home*/}
                                </a>
                                </li>
                                <li>
                                {/*    <a href="/#">*/}
                                {/*    <span className="icon-long-arrow-right mr-2"></span>*/}
                                {/*    */}
                                {/*    Blog*/}
                                {/*</a>*/}
                                    <NavLink
                                        // to={'/login'}
                                        to={'/blog'}
                                        // onClick={()=>{
                                        //     setLoginPageLoaded(true)
                                        // }}
                                        >
                                        <span className="icon-long-arrow-right mr-2"></span>
                                        Blog
                                    </NavLink>
                                </li>
                                <li>
                                    {/*<a href="/#">*/}
                                    {/*<span className="icon-long-arrow-right mr-2"></span>*/}
                                    {/*Konfrans</a>*/}
                                    <NavLink
                                        // to={'/login'}
                                        to={'/products/conference'}
                                        // onClick={()=>{
                                        //     setLoginPageLoaded(true)
                                        // }}
                                    >
                                        <span className="icon-long-arrow-right mr-2"></span>
                                        Konfrans
                                    </NavLink>
                                </li>
                                <li>
                                    {/*<a href="/#">*/}
                                    {/*<span className="icon-long-arrow-right mr-2"></span>*/}
                                    {/*Vebinar</a>*/}
                                    <NavLink
                                        // to={'/login'}
                                        to={'/products/webinar'}
                                        // onClick={()=>{
                                        //     setLoginPageLoaded(true)
                                        // }}
                                    >
                                        <span className="icon-long-arrow-right mr-2"></span>
                                        Vebinar
                                    </NavLink>

                                </li>
                                <li>
                                    {/*<a href="/#">*/}
                                    {/*<span className="icon-long-arrow-right mr-2"></span>*/}
                                    {/*Təlim</a>*/}
                                    <NavLink
                                        // to={'/login'}
                                        to={'/products/training'}
                                        // onClick={()=>{
                                        //     setLoginPageLoaded(true)
                                        // }}
                                    >
                                        <span className="icon-long-arrow-right mr-2"></span>
                                        Təlim
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/**/}
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4">
                            <h2 className="ftco-heading-2">Xidmətlər</h2>
                            <ul className="list-unstyled">
                                <li><a href="/#">
                                    <span className="icon-long-arrow-right mr-2"></span>
                                    Təcili Yardım
                                    {/*Emergency Services*/}
                                </a>
                                </li>
                                <li><a href="/#">
                                    <span className="icon-long-arrow-right mr-2"></span>
                                   Peşəkar Həkimlər
                                    {/*Qualified*/}
                                    {/*Doctors*/}
                                </a>
                                </li>
                                <li><a href="/#">
                                    <span className="icon-long-arrow-right mr-2"></span>
                                    Evinizdə Checkup
                                    {/*Outdoors*/}
                                    {/*Checkup*/}
                                </a>
                                </li>
                                <li><a href="/#">
                                    <span className="icon-long-arrow-right mr-2"></span>
                                    24 Saat Xidmət
                                    {/*24 Hours*/}
                                    {/*Services*/}
                                </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4">
                            <h2 className="ftco-heading-2">Sualınız var?</h2>
                            <div className="block-23 mb-3">
                                <ul>
                                    <li>
                                        <span className="icon icon-map-marker"></span>
                                        <span className="text">Ünvan : Bakı ş </span>
                                    </li>
                                    <li>
                                        <a href="/#">
                                            <span className="icon icon-phone"></span>
                                            <span className="text">+ 994505000000</span>
                                        </a>
                                    </li>
                                    <li><a href="/#"><span className="icon icon-envelope pr-4"></span>
                                        <span className="text">info@yourdomain.com</span>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p>
                            Copyright ©
                            {/*<script>document.write(new Date().getFullYear());</script>*/}
                            2021 All rights reserved |
                            <i className="icon-heart color-danger" aria-hidden="true"></i> by
                            <a href="/#" target="_blank"> Umud S</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};
