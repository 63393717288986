import React, {useEffect, useState} from 'react';
// import "./style.css"
import {BlogSingle} from "./BlogSingle";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {compose} from "redux";
import {getBlogById} from "../../redux/blogReducer";

const mapStateToProps=(state)=>({
    blogs:state.blogPage.selectedBlog,
})

const BlogSingleContainer = (props) => {
    const blogId=props.location.pathname.replace("/blogSingle/","")

    const [blogselected,setblogselectted]=useState()

    // useEffect(()=>{
    //     console.log(blogId)
    //     console.log(blogselected)
    //     props.getblogs()
    // },[])

    // useEffect( ()=>{
    //     setblogs(props.blogs)
    //     if(props.blogs!=null){
    //         let blogselectedd=props.blogs.filter(el=>el.id===blogId)[0]
    //         setblogselectted(blogselectedd)
    //     }
    // },[props.blogs])

    const [toRenderProduct,setRenderProduct]=useState(false)
    const [blogsN,setblogs]=useState(props.blogs != null ? props.blogs:null);
    debugger
    useEffect( ()=>{
        // console.log(props.match.params.blogId)
        window.scrollTo(0, 0)
        props.setIsHome(false)
        props.getBlogById(parseInt(blogId))
    },[])

    useEffect( ()=>{
        setblogs(props.blogs)
    },[props.blogs])
    useEffect(()=> {
        debugger
            // let blogselectedd=props.blogs.filter(el=>el.id===parseInt(blogId))[0]
             if(blogsN != null ) setRenderProduct(true)
        debugger
    },[blogsN])


    return (
        <div>
            {toRenderProduct && <BlogSingle
                blogId={blogId}
                blogs={blogsN}
                {...props}
            />}
        </div>
    );
};

export default compose(
    withRouter,
    connect(mapStateToProps,
        {getBlogById}
    )
)(BlogSingleContainer);
