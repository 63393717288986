import React, {useEffect} from 'react';
import image1 from "../../images/xbg_3.jpg.pagespeed.ic.Wx917eesOz.webp"
import image2 from "../../images/xabout.jpg.pagespeed.ic.YdESjG30xH.webp"
import image3 from "../../images/xbg_2.jpg.pagespeed.ic.pQrXJywxF9.webp"
import image4 from "../../images/xdoc-1.jpg.pagespeed.ic.myvVwp80RP.webp"
import image5 from "../../images/xbg_3.jpg.pagespeed.ic.Wx917eesOz.webp"
import image6 from "../../images/xdoc-2.jpg.pagespeed.ic.2QUsxWkZeQ.webp"
import image7 from "../../images/xdoc-3.jpg.pagespeed.ic.ssPmw2cMao.webp"
import image8 from "../../images/xdoc-4.jpg.pagespeed.ic.7oxaXGL52E.webp"
import image9 from "../../images/xbg_3.jpg.pagespeed.ic.Wx917eesOz.webp"
import { Link} from 'react-scroll'
import style from "./Home.module.css"
import {Col} from "react-bootstrap";


export const Home = ({setIsHome}) => {

    useEffect(()=>{
        window.scrollTo(0, 0)
        setIsHome(true);
    },[])
    return (
        <div className="w-100" >

            {/*HOME start*/}
            <section className="hero-wrap js-fullheight"
                     style={{
                         backgroundImage: `url(${image1})`,
                         // backgroundImage:"url(&quot;images/xbg_3.jpg.pagespeed.ic.Wx917eesOz.webp&quot;)",
                         height:'979px',
                         backgroundPosition:'50% 0%'
                     }}
                     id="home-section"
                     data-section="home"
                     data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-start"
                         data-scrollax-parent="true"
                         style={{height:'979px'}}
                        // style="height: 360px;"
                    >
                        <div className="col-md-6 pt-5 fadeInUp ftco-animated">
                            <div className="mt-5">
                                <span className="subheading">Medic School-a xoş gəldiniz</span>
                                <h1 className="mb-4">Peşəkar dəstək <br/>üçün yanınızdayıq</h1>
                                <p className="mb-4">Far far away, behind the word mountains, far from the countries
                                    Vokalia and Consonantia, there live the blind texts. Separated they live in
                                    Bookmarksgrove.</p>
                                <p>
                                    <Link to="contact-section"
                                          className="btn btn-primary py-3 px-4"
                                          spy={true}
                                          smooth={true}
                                          duration={500}
                                    >Əlaqə
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*HOME end*/}
            {/*ABOUT start*/}

            <section className="ftco-counter img ftco-section ftco-no-pt ftco-no-pb"
                     id="about-section">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-md-6 col-lg-5 d-flex">
                            <div className="img d-flex align-self-stretch align-items-center"
                                 style={{backgroundImage:`url(${image2})`}}
                            >
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7 pl-lg-5 py-md-5">
                            <div className="py-md-5">
                                <div className="row justify-content-start pb-3">
                                    <div className="col-md-12 heading-section p-4 p-lg-5 fadeInUp
                                    ftco-animated
                                     ">
                                        <h2 className="mb-4">We Are <span>Mediplus</span> A Medical Clinic</h2>
                                        <p>
                                            A small river named Duden flows by their place and supplies it with the
                                            necessary regelialia. It is a paradisematic country, in which roasted parts
                                            of sentences fly into your mouth. It is a paradisematic country, in which
                                            roasted parts of sentences fly into your mouth.
                                        </p>
                                        <p>
                                            <a href="/#" className="btn btn-primary py-3 px-4">Make an appointment</a>
                                            <a href="/#" className="btn btn-secondary py-3 px-4">Contact us</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*ABOUT end*/}

            {/*our services start*/}

            {/*<section className="ftco-section ftco-no-pt ftco-no-pb ftco-services-2 bg-light"*/}
            {/*         // ref={refAbout}*/}
            {/*>*/}
            {/*    <div className="container">*/}
            {/*        <div className="row d-flex">*/}
            {/*            <div className="col-md-7 py-5">*/}
            {/*                <div className="py-lg-5">*/}
            {/*                    <div className="row justify-content-center pb-5">*/}
            {/*                        <div className="col-md-12 heading-section fadeInUp*/}
            {/*                        ftco-animated*/}
            {/*                         ">*/}
            {/*                            <h2 className="mb-3">Our Services</h2>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="row  fadeInUp ftco-animated">*/}
            {/*                        <div className="col-md-6 d-flex align-self-stretch*/}

            {/*                           ">*/}
            {/*                            <div className="media block-6 services d-flex">*/}
            {/*                                <div className="icon justify-content-center align-items-center d-flex"><span*/}
            {/*                                    className="flaticon-ambulance"></span></div>*/}
            {/*                                <div className="media-body pl-md-4">*/}
            {/*                                    <h3 className="heading mb-3">Emergency Services</h3>*/}
            {/*                                    <p>A small river named Duden flows by their place and supplies it with*/}
            {/*                                        the necessary regelialia.</p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-md-6 d-flex align-self-stretch*/}
            {/*                        /!*ftco-animate*!/*/}
            {/*                        ">*/}
            {/*                            <div className="media block-6 services d-flex">*/}
            {/*                                <div className="icon justify-content-center align-items-center d-flex"><span*/}
            {/*                                    className="flaticon-doctor"></span></div>*/}
            {/*                                <div className="media-body pl-md-4">*/}
            {/*                                    <h3 className="heading mb-3">Qualified Doctors</h3>*/}
            {/*                                    <p>A small river named Duden flows by their place and supplies it with*/}
            {/*                                        the necessary regelialia.</p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-md-6 d-flex align-self-stretch*/}
            {/*                        ">*/}
            {/*                            <div className="media block-6 services d-flex">*/}
            {/*                                <div className="icon justify-content-center align-items-center d-flex"><span*/}
            {/*                                    className="flaticon-stethoscope"></span></div>*/}
            {/*                                <div className="media-body pl-md-4">*/}
            {/*                                    <h3 className="heading mb-3">Outdoors Checkup</h3>*/}
            {/*                                    <p>A small river named Duden flows by their place and supplies it with*/}
            {/*                                        the necessary regelialia.</p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-md-6 d-flex align-self-stretch*/}
            {/*                        /!*ftco-animate*!/*/}
            {/*                        ">*/}
            {/*                            <div className="media block-6 services d-flex">*/}
            {/*                                <div className="icon justify-content-center align-items-center d-flex">*/}
            {/*                                     <span*/}
            {/*                                         className="flaticon-24-hours">*/}

            {/*                                     </span>*/}
            {/*                                </div>*/}
            {/*                                <div className="media-body pl-md-4">*/}
            {/*                                    <h3 className="heading mb-3">24 Hours Service</h3>*/}
            {/*                                    <p>A small river named Duden flows by their place and supplies it with*/}
            {/*                                        the necessary regelialia.</p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-5 d-flex">*/}
            {/*                <div className="appointment-wrap bg-white p-4 p-md-5 d-flex align-items-center">*/}
            {/*                    <form action="/#" className="appointment-form  fadeInUp ftco-animated*/}
            {/*                     "*/}
            {/*                    >*/}
            {/*                        <h3>Free Consultation</h3>*/}
            {/*                        <div className="">*/}
            {/*                            <div className="form-group">*/}
            {/*                                <input type="text" className="form-control" placeholder="First Name"/>*/}
            {/*                            </div>*/}
            {/*                            <div className="form-group">*/}
            {/*                                <input type="text" className="form-control" placeholder="Last Name"/>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="">*/}
            {/*                            <div className="form-group">*/}
            {/*                                <div className="form-field">*/}
            {/*                                    <div className="select-wrap">*/}
            {/*                                        <div className="icon"><span className="ion-ios-arrow-down"></span>*/}
            {/*                                        </div>*/}
            {/*                                        <select name="" id="" className="form-control">*/}
            {/*                                            <option value="">Select Your Services</option>*/}
            {/*                                            <option value="">Neurology</option>*/}
            {/*                                            <option value="">Cardiology</option>*/}
            {/*                                            <option value="">Dental</option>*/}
            {/*                                            <option value="">Ophthalmology</option>*/}
            {/*                                            <option value="">Other Services</option>*/}
            {/*                                        </select>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="form-group">*/}
            {/*                                <input type="text" className="form-control" placeholder="Phone"/>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="">*/}
            {/*                            <div className="form-group">*/}
            {/*                                <div className="input-wrap">*/}
            {/*                                    <div className="icon">*/}
            {/*                                        <span className="ion-md-calendar"></span>*/}
            {/*                                    </div>*/}
            {/*                                    <input type="text" className="form-control appointment_date"*/}
            {/*                                           placeholder="Date"/>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="form-group">*/}
            {/*                                <div className="input-wrap">*/}
            {/*                                    <div className="icon">*/}
            {/*                                        <span className="ion-ios-clock"></span>*/}
            {/*                                    </div>*/}
            {/*                                    <input type="text" className="form-control appointment_time"*/}
            {/*                                           placeholder="Time"/>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="">*/}
            {/*                            <div className="form-group">*/}
            {/*                                 <textarea name="" id=""*/}
            {/*                                     // cols="30"*/}
            {/*                                     // rows="2"*/}
            {/*                                           className="form-control"*/}
            {/*                                           placeholder="Message">*/}

            {/*                                 </textarea>*/}
            {/*                            </div>*/}
            {/*                            <div className="form-group">*/}
            {/*                                <input type="submit" value="Appointment"*/}
            {/*                                       className="btn btn-secondary py-3 px-4"/>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </form>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section> */}
            {/*our services end */}

            {/*    about us start*/}
            <section className="ftco-intro img"
                     style={{backgroundImage:`url(${image3})`}}
            >
                <div className="overlay"></div>
                <div className="container">
                    <div className="row justify-content-center  fadeInUp ftco-animated">
                        <div className="col-md-9 text-center">
                            <h2>
                                Sizin sağlamlığınız bizim öncəliyimizdir
                                {/*Your Health is Our Priority*/}
                            </h2>
                            <p>We can manage your dream building A small river named Duden flows by their place</p>
                            <p className="mb-0"><a href="/#" className="btn btn-white px-4 py-3">Search Places</a></p>
                        </div>
                    </div>
                </div>
            </section>
            {/*    about us  end*/}
            {/*    departament start*/}

            <section className="ftco-section ftco-no-pt ftco-no-pb" id="department-section"
            >
                <div className="container-fluid px-0">
                    <div className="row no-gutters">
                        <div className="col-md-4 d-flex">
                            <div className="img img-dept align-self-stretch"
                                 style={{backgroundImage:`url(${image4})`}}

                            ></div>
                        </div>
                        <div className="col-md-8">
                            <div className="row no-gutters fadeInUp ftco-animated">
                                <div className="col-md-4">
                                    <div className="department-wrap p-4 ">
                                        <div className="text p-2 text-center">
                                            <div className="icon">
                                                <span className="flaticon-stethoscope"></span>
                                            </div>
                                            <h3><a href="/#">
                                                Nevrologiya
                                                {/*Neurology*/}
                                            </a></h3>
                                            <p>Far far away, behind the word mountains</p>
                                        </div>
                                    </div>
                                    <div className="department-wrap p-4 {/*{/*ftco-animate*/}*/}">
                                        <div className="text p-2 text-center">
                                            <div className="icon">
                                                <span className="flaticon-stethoscope"></span>
                                            </div>
                                            <h3><a href="/#">
                                                Cərrahiyyə
                                                {/*Surgical*/}
                                            </a></h3>
                                            <p>Far far away, behind the word mountains</p>
                                        </div>
                                    </div>
                                    <div className="department-wrap p-4 {/*{/*ftco-animate*/}*/}">
                                        <div className="text p-2 text-center">
                                            <div className="icon">
                                                <span className="flaticon-stethoscope"></span>
                                            </div>
                                            <h3><a href="/#">
                                                Stomotologiya
                                                {/*Dental*/}
                                            </a></h3>
                                            <p>Far far away, behind the word mountains</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="department-wrap p-4 {/*{/*ftco-animate*/}*/}">
                                        <div className="text p-2 text-center">
                                            <div className="icon">
                                                <span className="flaticon-stethoscope"></span>
                                            </div>
                                            <h3><a href="/#">
                                                Oftalmalogiya
                                                {/*Opthalmology*/}
                                            </a></h3>
                                            <p>Far far away, behind the word mountains</p>
                                        </div>
                                    </div>
                                    <div className="department-wrap p-4 {/*{/*ftco-animate*/}*/}">
                                        <div className="text p-2 text-center">
                                            <div className="icon">
                                                <span className="flaticon-stethoscope"></span>
                                            </div>
                                            <h3><a href="/#">
                                                Kardiologiya
                                                {/*Cardiology*/}
                                            </a></h3>
                                            <p>Far far away, behind the word mountains</p>
                                        </div>
                                    </div>
                                    <div className="department-wrap p-4 {/*{/*ftco-animate*/}*/}">
                                        <div className="text p-2 text-center">
                                            <div className="icon">
                                                <span className="flaticon-stethoscope"></span>
                                            </div>
                                            <h3><a href="/#">
                                                Travmatalogiya
                                                {/*Traumatology*/}
                                            </a></h3>
                                            <p>Far far away, behind the word mountains</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="department-wrap p-4 {/*{/*ftco-animate*/}*/}">
                                        <div className="text p-2 text-center">
                                            <div className="icon">
                                                <span className="flaticon-stethoscope"></span>
                                            </div>
                                            <h3><a href="/#">
                                                Nuclear Magnetic
                                            </a></h3>
                                            <p>Far far away, behind the word mountains</p>
                                        </div>
                                    </div>
                                    <div className="department-wrap p-4 {/*{/*ftco-animate*/}*/}">
                                        <div className="text p-2 text-center">
                                            <div className="icon">
                                                <span className="flaticon-stethoscope"></span>
                                            </div>
                                            <h3><a href="/#">X-ray</a></h3>
                                            <p>Far far away, behind the word mountains</p>
                                        </div>
                                    </div>
                                    <div className="department-wrap p-4 {/*{/*ftco-animate*/}*/}">
                                        <div className="text p-2 text-center">
                                            <div className="icon">
                                                <span className="flaticon-stethoscope"></span>
                                            </div>
                                            <h3><a href="/#">Cardiology</a></h3>
                                            <p>Far far away, behind the word mountains</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*    departament end*/}
            {/*    our Teachers start*/}
            <section className="ftco-section" id="doctor-section"
            >
                <div className="container-fluid px-5">
                    <div className="row justify-content-center mb-5 pb-2 fadeInUp ftco-animated">
                        <div className="col-md-8 text-center heading-section {/*{/*ftco-animate*/}*/}">
                            <h2 className="mb-4">Təlimçilər</h2>
                            <p>Bizim dərin bilikli tədrisçilərimizlə tanış olun</p>
                        </div>
                    </div>
                    <div className="row fadeInUp ftco-animated">
                        <div className="col-md-6 col-lg-3 {/*{/*ftco-animate*/}*/}">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div className="img align-self-stretch"
                                         style={{backgroundImage:`url(${image5})`}}
                                    ></div>
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3 className="mb-2">Dr. Lloyd Wilson</h3>
                                    <span className="position mb-2">Neurologist</span>
                                    <div className="faded">
                                        <p>I am an ambitious workaholic, but apart from that, pretty simple person.</p>
                                        <ul className="ftco-social text-center">
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-twitter"></span></a></li>
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-facebook"></span></a></li>
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-google-plus"></span></a></li>
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-instagram"></span></a></li>
                                        </ul>
                                        <p><a href="/#" className="btn btn-primary">Book now</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 {/*{/*ftco-animate*/}*/}">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div className="img align-self-stretch"
                                         style={{backgroundImage:`url(${image6})`}}
                                    ></div>
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3 className="mb-2">Dr. Rachel Parker</h3>
                                    <span className="position mb-2">Ophthalmologist</span>
                                    <div className="faded">
                                        <p>I am an ambitious workaholic, but apart from that, pretty simple person.</p>
                                        <ul className="ftco-social text-center">
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-twitter"></span></a></li>
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-facebook"></span></a></li>
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-google-plus"></span></a></li>
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-instagram"></span></a></li>
                                        </ul>
                                        <p><a href="/#" className="btn btn-primary">Book now</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 {/*{/*ftco-animate*/}*/}">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div className="img align-self-stretch"
                                         style={{backgroundImage:`url(${image7})`}}

                                    ></div>
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3 className="mb-2">Dr. Ian Smith</h3>
                                    <span className="position mb-2">Dentist</span>
                                    <div className="faded">
                                        <p>I am an ambitious workaholic, but apart from that, pretty simple person.</p>
                                        <ul className="ftco-social text-center">
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-twitter"></span></a></li>
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-facebook"></span></a></li>
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-google-plus"></span></a></li>
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-instagram"></span></a></li>
                                        </ul>
                                        <p><a href="/#" className="btn btn-primary">Book now</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 {/*{/*ftco-animate*/}*/}">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div className="img align-self-stretch"
                                         style={{backgroundImage:`url(${image8})`}}
                                        // style={{backgroundImage:"url(images/xdoc-4.jpg.pagespeed.ic.7oxaXGL52E.webp)"}}
                                    ></div>
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3 className="mb-2">Dr. Alicia Henderson</h3>
                                    <span className="position mb-2">Pediatrician</span>
                                    <div className="faded">
                                        <p>I am an ambitious workaholic, but apart from that, pretty simple person.</p>
                                        <ul className="ftco-social text-center">
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-twitter"></span></a></li>
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-facebook"></span></a></li>
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-google-plus"></span></a></li>
                                            <li className="{/*{/*ftco-animate*/}*/}"><a href="/#"><span
                                                className="icon-instagram"></span></a></li>
                                        </ul>
                                        <p><a href="/#" className="btn btn-primary">Book now</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*    our Teachers end*/}
            {/*    statistic start*/}

            <section className="ftco-facts img ftco-counter"
                     style={{backgroundImage:`url(${image9})`}}

                // style={{backgroundImage:"url(images/xbg_3.jpg.pagespeed.ic.Wx917eesOz.webp)"}}
            >
                <div className="overlay"></div>
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-5 heading-section heading-section-white">
                            <span className="subheading">Fun facts</span>
                            <h2 className="mb-4">Over 5,100 patients trust us</h2>
                            <p className="mb-0"><a href="/#" className="btn btn-secondary px-4 py-3">Make an
                                appointment</a></p>
                        </div>
                        <div className="col-md-7">
                            <div className="row pt-4">
                                <div className="col-md-6 d-flex justify-content-center counter-wrap {/*{/*ftco-animate*/}*/}">
                                    <div className="block-18">
                                        <div className="text">
                                            <strong className="number" data-number="30">30</strong>
                                            <span>Years of Experienced</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex justify-content-center counter-wrap {/*{/*ftco-animate*/}*/}">
                                    <div className="block-18">
                                        <div className="text">
                                            <strong className="number" data-number="4500">4,500</strong>
                                            <span>Happy Patients</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex justify-content-center counter-wrap {/*{/*ftco-animate*/}*/}">
                                    <div className="block-18">
                                        <div className="text">
                                            <strong className="number" data-number="84">84</strong>
                                            <span>Number of Doctors</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex justify-content-center counter-wrap {/*{/*ftco-animate*/}*/}">
                                    <div className="block-18">
                                        <div className="text">
                                            <strong className="number" data-number="300">300</strong>
                                            <span>Number of Staffs</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*    statistic end*/}

            {/*    contact start*/}
            <section className="ftco-section contact-section"
                     id="contact-section">
                <div className="container fadeInUp ftco-animated">
                    <div className="row justify-content-center mb-5 pb-3">
                        <div className="col-md-7 heading-section text-center
                        {/*{/*ftco-animate*/}*/}
                        ">
                            <h2 className="mb-4">Bizimlə əlaqə</h2>
                            <p>Sosial şəbəkələrdə və ya digər ünsiyyət vasitələri ilə bizimlə əlaqə saxlayaraq suallarınıza cavab ala bilərsiz</p>
                        </div>
                    </div>
                    <div className="row d-flex contact-info mb-5">
                        <div className="col-md-6  d-flex
                        {/*ftco-animate*/}">
                                <div className="align-self-stretch box p-4 text-center bg-light">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <i className="fa fa-map" aria-hidden="true"></i>
                                    </div>
                                    <h3 className="mb-4">Ünvan</h3>
                                    <p>198 West 21th Street, Suite 721 New York NY 10016</p>
                                </div>
                            <div className="align-self-stretch box p-4 text-center bg-light">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <i className="fas fa-phone" aria-hidden="true"></i>
                                </div>
                                <h3 className="mb-4">Əlaqə Nömrəsi</h3>
                                <p><a href="tel://1234567920">+ 1235 2355 98</a></p>
                            </div>
                            <div className="align-self-stretch box p-4 text-center bg-light">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                </div>
                                <h3 className="mb-4">Email</h3>
                                <p><a href="mailto:info@yoursite.com">info@yoursite.com</a></p>
                            </div>
                        </div>

                        <div className="col-md-6  d-flex justify-content-center">
                            <div className={style.sosials_main}>
                                <div className={style.wrapperHorisantal}>
                                    <div className={`${style.icon} ${style.facebook}`}
                                         onClick={()=>{
                                             window.open("https://instagram.com/medicschool_","_blank")
                                         }}
                                    >
                                        <div className={style.tooltip}>
                                            Facebook
                                        </div>
                                        <span><i className="fab fa-facebook-f"></i></span>
                                    </div>
                                    <div className={`${style.icon} ${style.youtube}`}
                                         onClick={()=>{
                                             window.open("https://youtube.com/channel/UCrp7V6IrrzMgFevajTcm6TQ","_blank")
                                         }}>
                                        <div className={style.tooltip}>
                                            Youtube
                                        </div>
                                        <span><i className="fab fa-youtube"></i></span>
                                    </div>
                                    <div className={`${style.icon} ${style.instagram}`}
                                         onClick={()=>{
                                             // alert("fb")
                                             window.open("https://instagram.com/medicschool_","_blank")
                                             //https://www.facebook.com/medicschool/
                                         }}>
                                        <div className={style.tooltip}>
                                            Instagram
                                        </div>
                                        <span><i className="fab fa-instagram"></i></span>
                                    </div>
                                    <div className={`${style.icon} ${style.telegram}`}
                                         onClick={()=>{
                                             window.open("https://t.me/medicschool_tv","_blank")
                                         }}>
                                        <div className={style.tooltip}>
                                            Telegram
                                        </div>
                                        <span><i className="fab fa-telegram"></i></span>
                                    </div>
                                </div>
                            </div>
                            {/*<Formik*/}
                            {/*    initialValues={{*/}
                            {/*        name: '',*/}
                            {/*        phone: '',*/}
                            {/*        profession: '',*/}
                            {/*        workplace: '',*/}
                            {/*        position: '',*/}
                            {/*        passport: '',*/}
                            {/*        email: ''*/}
                            {/*    }}*/}
                            {/*    // validationSchema={validate}*/}
                            {/*    onSubmit={(formValues)=> {*/}
                            {/*        // alert("sass")*/}
                            {/*        console.log("sass")*/}
                            {/*    }*/}
                            {/*    }*/}

                            {/*>*/}
                            {/*    {props => (*/}
                            {/*        <Form className="bg-light p-5 contact-form">*/}
                            {/*            <div className="form-group">*/}
                            {/*                <input type="text"*/}
                            {/*                       className="form-control"*/}
                            {/*                       placeholder="Adınız"/>*/}
                            {/*            </div>*/}
                            {/*            <div className="form-group">*/}
                            {/*                <input type="text"*/}
                            {/*                       className="form-control"*/}
                            {/*                       placeholder="Əlaqə telefonu"/>*/}
                            {/*            </div>*/}
                            {/*            <div className="form-group">*/}
                            {/*                <input type="text"*/}
                            {/*                       className="form-control"*/}
                            {/*                       placeholder="Mövzu"/>*/}
                            {/*            </div>*/}
                            {/*            <div className="form-group">*/}
                            {/*         <textarea name="" id=""*/}
                            {/*                   style={{}}*/}
                            {/*             // cols="30"*/}
                            {/*             // rows="7"*/}
                            {/*                   className="form-control"*/}
                            {/*                   placeholder="Message">*/}
                            {/*         </textarea>*/}
                            {/*            </div>*/}
                            {/*            <div className="form-group">*/}
                            {/*                <input type="submit"*/}
                            {/*                       value="Send Message"*/}
                            {/*                       className="btn btn-secondary py-3 px-5"/>*/}
                            {/*            </div>*/}
                            {/*        </Form>*/}
                            {/*    )}*/}
                            {/*</Formik>*/}

                        </div>
                    </div>
                </div>
                {/*<div className="col-md-6 col-lg-3 d-flex /!*ftco-animate*!/">*/}
                {/*    <div className="align-self-stretch box p-4 text-center bg-light">*/}
                {/*        <div className="icon d-flex align-items-center justify-content-center">*/}
                {/*            <span className="icon-phone2"></span>*/}
                {/*        </div>*/}
                {/*        <h3 className="mb-4">Əlaqə Nömrəsi</h3>*/}
                {/*        <p><a href="tel://1234567920">+ 1235 2355 98</a></p>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="col-md-6 col-lg-3 d-flex /!*ftco-animate*!/">*/}
                {/*   */}
                {/*</div>*/}
                {/*<div className="col-md-6 col-lg-3 d-flex /!*ftco-animate*!/">*/}
                {/*    <div className="align-self-stretch box p-4 text-center bg-light">*/}
                {/*        <div className="icon d-flex align-items-center justify-content-center">*/}
                {/*            <span className="icon-globe"></span>*/}
                {/*        </div>*/}
                {/*        <h3 className="mb-4">Website</h3>*/}
                {/*        <p><a href="/#">yoursite.com</a></p>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="row no-gutters block-9">*/}
                {/*
                            <div className="col-md-6 d-flex">
                                <div id="map" className="bg-white"
                                     style={{position:"relative",overflow:"hidden"}}
                                >
                                */}
            </section>
            {/*    contact end*/}

        </div>
    );
};
