import React, {useEffect, useState} from 'react';
import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

const Modall =({visibility,isSuccess})=> {

    const [visible, setVisible] =useState(visibility?visibility:false)

    const show= ()=> {
        setVisible(true)
    }

    const hide = () => {
        setVisible(false)
    }
    useEffect(()=>{
        if(visibility){
            show()
        }else hide()
    },[visibility])
        return (
            <div>
                {/*<button style={{visibility:"hidden"}} */}
                {/*        onClick={show}>show</button>*/}

                <Rodal visible={visible} onClose={hide}>
                    {isSuccess &&
                    <>
                    <div style={{textAlign:"center",marginTop:"1rem"}}>
                        <h2>Uğurlu əməliyyat</h2>
                    </div>
                    <div style={{textAlign:"center", marginTop:"2rem",color:"green"}}>Təşəkkür edirik. Ödəniş qəbul olundu.</div>
                    <div style={{textAlign:"center"}}>Əməkdaşlarımız qısa müddətdə sizinlə əlaqə saxlayacaq</div>
                    </>
                    }
                    {!isSuccess &&
                    <>
                    <div style={{textAlign:"center",marginTop:"1rem"}}>
                        <h2>Uğursuz əməliyyat</h2>
                    </div>
                    <div style={{textAlign:"center", marginTop:"2rem",color:"red"}}>Ödəniş zamanı xəta.</div>
                    <div style={{textAlign:"center"}}>Zəhmət olmasa təkrar yoxlayın və ya bizimlə əlaqə saxlayın</div>
                    </>
                    }
                </Rodal>
            </div>
        );
    }

export default Modall
