import React from 'react';
import {productsAPI} from "../api/api";
import ShowNotification from "../components/common/Notification/ShowNotification";
const SET_PRODUCTS_DATA = 'SET_PRODUCTS_DATA';
const IS_LOADING = 'IS_LOADING';

export const setPorductsData = (payload) => {
    return {
        type: SET_PRODUCTS_DATA,
        payload: payload
    }
}
export const isLoading = (isLoadingStatus) => {
    return {
        type: IS_LOADING,
        isLoadingStatus
    }
}

let initialState = {
    products:[
            {
                "id": 1,
                "title": "title 0",
                "content": "content 0",
                "imgUrl": "100",
                "youtubeLink": "1010",
                "status": 1,
                "productType": 1,
                "price": "1500.00",
                "startDate": "2021-06-28 14:00:00",
                "endDate": "2021-08-28 18:00:00",
                // "created_at": "2021-07-21T15:48:51.000000Z",
                // "updated_at": "2021-07-21T15:48:51.000000Z"
            }
         ],
    totalPages:4,
    totalElements:31,
    size:10
}

let productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRODUCTS_DATA:
            return {
                ...state,
                products:action.payload.products,
                totalPages: action.payload.totalPages,
                size: action.payload.size,
                totalElements: action.payload.totalElements,
            }
        default :
            return state;
    }
}


export const getProducts = (page,size) => {
    return async (dispatch) => {
        let response = await productsAPI.getProducts(page,size);
        if (response) {
            dispatch(setPorductsData(response));
        }else {
            let errorMessage='Server: Response Error';
            ShowNotification("Error",errorMessage,"danger",3000)
        }
    }
}

export default productsReducer;
